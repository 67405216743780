body {
  margin: 0;
  pading: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

/* chat history css */

.chat-history {
  width: 17%; /* Set the width of the chat history to 17% */
  padding: 10px;
  background-color: #f5f5f5;
}

.chat-history h2 {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
}

.new-chat-button {
  border: none;
  width: 100%;
  height: 32px;
  padding: 0px 8px 0px 8px;
  border-radius: 8px;
  font-size: 16px;
  color: #27807C;
  margin-top: 12px;
  border: 1px solid #27807C;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-chat-button:hover {
  border: 1px solid #1B5A57;
  color: #1B5A57;
}

.logout-button {
  border: none;
  width: 100%;
  height: 32px;
  padding: 0px 8px 0px 8px;
  border-radius: 8px;
  font-size: 16px;
  color: #C25049;
  margin-top: 12px;
  border: 1px solid #C25049;
}

.logout-button:hover {
  border: 1px solid #883833;
  color: #883833;
}

.chat-history ul {
  padding: 0; /* Remove padding from the list */
  margin: 0; /* Remove margin from the list */
  list-style-type: none; /* Remove bullet points from the list items */
  clear: both; /* Clear the float so the list appears below the button */
  overflow-y: auto;
  height: calc(100vh - 170px);
}

.chat-entry {
  padding: 4px 4px 4px 8px;
  margin-right: 8px;
  margin-bottom: 4px;
  border: 1px solid transparent;
  border-radius: 8px;
  background-color: transparent;
  cursor: pointer;
  text-align: left; /* Align the text to the left */
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
}

.chat-entry:hover {
  background-color: #d8d8d8;
  border: 1px solid #d8d8d8;
}

.chat-entry-text {
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
}

.loading-text {
  margin-bottom: 10px;
  margin-left: 20px;
}

/* chat windown css */

.chat-window {
  width: 83%; 
  display: flex;
  flex-direction: column;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #ffffff;
}

.message {
  margin-bottom: 30px;
  /* border-radius: 10px; */
  width: fit-content;
}

.message.user {
  background-color: #f0f0f0;
  margin-left: auto;
  max-width: 800px;
  text-align: left;
  border-radius: 12px;
  font-size: 14px;
  padding: 10px;
}

.message.bot {
  /* background-color: #f1f1f1; */
  margin-right: auto;
  text-align: left;
  font-size: 14px;
  border-radius: 12px;
  /* max-width: 1000px; */
  width: 100%;
}

.syntaxhighlighter {
  background-color: #000;
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
}

.input-form-container {
  display: flex;
  margin-bottom: 48px;
  align-items: center;
  justify-content: center;
  /* margin-top: 12px; */
}

.input-form {
  width: 60%;
  /* border: 1px solid black; */
  /* display: flex;
  align-items: center;
  height: 48px; */
  
}

.message-input {
  resize: none; 
  width: 100%;
  border-radius: 24px;
  font-size: 14px;
  line-height: 20px;
  outline: none;  
  border: none;
  background-color: #f0f0f0;
  padding-top: 4px;
  padding-right: 8px;
  padding-left: 24px;
  padding-right: 24px;
  max-height: 200px;
  min-height: 30px;
  align-content: center;
}


.send-button {
  position: absolute;
  right: -10px;
  bottom: 10px;
  padding: 8px 8px;
  border: none;
  border-radius: 1000px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send-button:hover {
  background-color: rgb(177, 177, 177);
}


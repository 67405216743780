.container {
    width: 100vw;
    height: 100vh;
    padding: 0;
    margin: 0;
    background-image: url('../assets/wc_background.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top;
}


.blurred-box {
    position: relative;
    width: 350px;
    height: auto;
    top: calc(50% - 175px);
    left: calc(50% - 175px);
    background: inherit;
    border-radius: 2px;
    overflow: hidden;
    border: 1px solid white;
    border-radius: 8px;
    text-align: center;
}

.blurred-box:after {
    content: '';
    width: 350px;
    height: auto;
    background: inherit;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: inset 0 0 0 200px rgba(255, 255, 255, 0.05);
    filter: blur(5px);
}

.login-title {
    color: white;
}
form {
    position: relative;
    text-align: center;
    z-index: 1;
    padding: 0 24px 0 24px;
}
.input-field {
    width: 100%;
    margin-bottom: 24px;
    height: 32px;
    border-radius: 8px;
    background: var(--Primary-Background-Tint-Rest, #E9F2F2);
    border: 2px solid var(--Primary-Stroke-1-Rest, #27807C);
    outline: none;
    font-size: 16px;
}


.login-button {
    background: #27807C;
    border: none;
    width: 100%;
    height: 48px;
    padding: 0px 16px 0px 16px;
    border-radius: 10px;
    font-size: 16px;
    color: white;
    margin-bottom: 12px;
}

.login-button:hover {
    background: #1B5A57;
}